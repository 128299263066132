import React, { useCallback } from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import * as Icon from "react-feather";
import { useHistory } from "react-router-dom";

const UserDropdown = () => {
  const history = useHistory();

  const onLogoutClick = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user-id");
    history.push("/login");
  }, [history]);

  return (
    <DropdownMenu right>
      <DropdownItem tag="a" onClick={() => history.push('/change-password')}>
        <Icon.Lock size={14} className="mr-50" />
        <span className="align-middle">Change Password</span>
      </DropdownItem>
      <DropdownItem tag="a" onClick={() => history.push('/add-user')}>
        <Icon.Users size={14} className="mr-50" />
        <span className="align-middle">Add User</span>
      </DropdownItem>
      <hr />
      <DropdownItem tag="a" onClick={onLogoutClick}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

const NavbarUser = (props) => {
  return (
    <ul className="nav navbar-nav navbar-nav-user float-right">
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none" style={{
            width: 120
          }}>
            <span className="user-name text-bold-600">{props.userName}</span>
            <span className="user-status">Available</span>
          </div>
          <span data-tour="user">
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                background: "grey",
              }}
            />
          </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  );
};
export default NavbarUser;
