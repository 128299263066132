import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "APP"
  },
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layers size={20} />,
    navLink: "/",
  },
  {
    id: "project",
    title: "Project",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    navLink: "/project",
  },
  {
    type: "groupHeader",
    groupTitle: "MASTER DATA"
  },
  {
    id: "entitas",
    title: "Entitas",
    type: "item",
    icon: <Icon.Database size={20} />,
    navLink: "/entitas",
  },
  {
    id: "bpp",
    title: "Komponen Utama",
    type: "item",
    icon: <Icon.Database size={20} />,
    navLink: "/bpp",
  },
  {
    id: "holding",
    title: "Holding / Subholding",
    type: "item",
    icon: <Icon.Database size={20} />,
    navLink: "/holding",
  },
]

export default navigationConfig
